import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import grubhub from "../images/grubhub-button.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home-banner">
      <div>
        <h1>Welcome to Thai &amp; I</h1>

        <p>
          <a href="https://thaiandirestaurant.dine.online/">
            <button>ORDER FOR DELIVERY</button>
          </a>
        </p>
        <p>
          <a href="https://www.toasttab.com/thai-i/v3">
            <button>ORDER FOR PICK UP</button>
          </a>
        </p>
      </div>
    </div>
    <section id="online">
      <div className="container">
        <h2>Order Delivery Here</h2>
        <div className="center">
          <a
            target="_blank"
            href="https://www.grubhub.com/restaurant/thai-and-i-restaurant-274-south-st-shrewsbury/2196678?classicAffiliateId=%2Fr%2Fw%2F2196678%2F&utm_source=https://restaurant.grubhub.com&utm_medium=OOL&utm_campaign=order%20online&utm_content=2196678"
          >
            <img src={grubhub} alt="Grubhub" />
          </a>
        </div>
      </div>
    </section>
    <section id="about">
      <div className="container">
        <h2>About</h2>
        <p className="center">
          From Songkhla (a province in southern Thailand) to Shrewsbury, Thai
          &amp; I has taken great pleasure and pride in serving our guests a
          wide variety of Thai dishes — mouth-watering appetizers, flavorful
          noodle soup, original Thai curries, fresh season, and also an
          extensive vegetarian menu. We always use the freshest ingredients, a
          unique balance of flavors and an exquisite combination of spices.
        </p>

        <p>
          This restaurant business has been our family’s labor of love that I
          started with my mom, a proud culinary teacher. We hope that you have
          enjoyed your visit and if you haven’t tried us, we hope you will get a
          chance to. We would love the opportunity! Thank you, Shrewsbury and
          surrounding towns for your support. We are truly grateful to be here.
        </p>
      </div>
    </section>

    <section id="menu">
      <div className="container" id="accordion">
        <h2>Menu</h2>
        <div class="card">
          <div class="card-header" id="Appetizers">
            <h5 class="mb-0">
              <button
                class="btn btn-link"
                data-toggle="collapse"
                data-target="#appetizer"
                aria-expanded="true"
                aria-controls="appetizer"
              >
                Appetizers (A)
              </button>
            </h5>
          </div>

          <div
            id="appetizer"
            class="collapse show"
            aria-labelledby="Appetizers"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                1. Edamame <span>6.95</span>
              </h4>
              <p>
                Seasoned Japanese snack; steamed soybean pods and seasoned with
                salt
              </p>
              <h4>
                2. Thai Crispy Rolls <span>7.95</span>
              </h4>
              <p>
                Crispy rice paper filled with chicken and mixed vegetables;
                served with sweet chili sauce
              </p>
              <h4>
                3. Thai Soft Spring Rolls <span>7.95</span>
              </h4>
              <p>
                Steamed vermicelli, cooked chicken, lettuce, carrot, cucumber
                wrapped in fresh spring roll skins, served with sweet and sour
                sauce, and topped with ground peanuts
              </p>
              <h4>
                4. Tofu Triangles <span>7.95</span>
              </h4>
              <p>
                Deep-fried tofu served with sweet and sour sauce topped with
                ground peanut
              </p>
              <h4>
                5. Pork Dumplings <span>7.95</span>
              </h4>
              <p>Steamed or fried pork dumplings served with light soy sauce</p>
              <h4>
                6. Chive Pancake <span>7.95</span>
              </h4>
              <p>Fried or steamed chive pancake served with ginger sauce</p>
              <h4>
                7. Golden Triangle <span>7.95</span>
              </h4>
              <p>
                Deep-fried egg roll skins stuffed with vegetables, served with
                sweet chili sauce
              </p>
              <h4>
                8. Scallion Pancakes <span>7.95</span>
              </h4>
              <h4>
                9. Shrimp Shumai (steamed or deep-fried) <span>7.95</span>
              </h4>
              <p>Shrimp dumplings served with ginger sauce</p>
              <h4>
                10. Vegetable Dumplings <span>7.95</span>
              </h4>
              <p>
                Steamed or fried vegetables dumplings served with ginger sauce
              </p>
              <h4>
                11. Chicken Satay <span>8.95</span>
              </h4>
              <p>
                Charcoal-grilled chicken tenders on skewers, marinated with Thai
                spices; served with peanut sauce and cucumber sauce
              </p>
              <h4>
                12. Chicken Wings <span>8.95</span>
              </h4>
              <p>
                Deep fried chicken wings marinated with herbs and house sauce
              </p>
              <h4>
                13. Crab Rangoon <span>8.95</span>
              </h4>
              <p>
                Crabmeat and cream cheese filling inside crispy wonton wrappers
              </p>
              <h4>
                14. Crispy Brussels Sprout <span>8.95</span>
              </h4>
              <p>Brussels sprout and seasoned Thai style</p>
              <h4>
                15. Coconut Shrimp <span>8.95</span>
              </h4>
              <p>Shrimp in coconut batter, deep fried until golden</p>
              <h4>
                16. Shrimp Tempura <span>8.95</span>
              </h4>
              <p>Shrimp in tempura batter, deep fried until crispy</p>
              <h4>
                17. Vegetarian Sampler <span>14.95</span>
              </h4>
              <p>
                (2) Vegetable rolls, (4) tofu triangle, (4) chive pancake,
                crispy brussels sprouts
              </p>
              <h4>
                18. Sampler <span>15.95</span>
              </h4>
              <p>
                Combination of (2) crispy rolls, (2) vegetable rolls, (2) samosa
                bag, (2) crab rangoon, (2) coconut shrimp, (2) chicken satay
                serve with peanut sauce and cucumber sauce
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="sal">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#salads"
                aria-expanded="false"
                aria-controls="salads"
              >
                Salads (S)
              </button>
            </h5>
          </div>
          <div
            id="salads"
            class="collapse"
            aria-labelledby="sal"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                1. Chicken or Beef Salad (Larb Gai/Yum Nue) <span>11.95</span>
              </h4>
              <p>
                Ground chicken or slice beef with cucumber, lemon grass, ground
                roasted rice grain, scallion, cilantro mixed in spicy lime juice
              </p>
              <h4>
                2. Papaya Salad (Som Tum) <span>11.95</span>
              </h4>
              <p>
                Shredded papaya mixed with string beans, tomato, chili in spicy
                lime sauce, and topped with peanuts. Served with a side of
                sticky rice
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="soup">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#soups"
                aria-expanded="false"
                aria-controls="soups"
              >
                Soups (SP)
              </button>
            </h5>
          </div>
          <div
            id="soups"
            class="collapse"
            aria-labelledby="soup"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                1. Tom Yum <span>6.95</span>
              </h4>
              <p>
                The world-famous Thai soup spiced with chilies, lemon grass,
                mushrooms, and lime juice; with choice of chicken or shrimp
              </p>
              <h4>
                2. Tom Kha Gai <span>6.95</span>
              </h4>
              <p>
                Mild chicken coconut milk soup with galangal root, lime juice,
                mushrooms, and scallions
              </p>
              <h4>
                3. Wonton Soup <span>6.95</span>
              </h4>
              <p>
                Soup with vegetables and wonton wrappers stuffed with ground
                chicken
              </p>
              <h4>
                4. Pho <span>11.95</span>
              </h4>
              <p>
                Rice noodles in bone broth with choice of chicken, beef, or
                pork, served in a large bowl
              </p>
              <h4>
                5. Seafood Tom Yum <span>7.95</span>
              </h4>
              <p>Tom yum soup with shrimp, squid, and scallops</p>
              <h4>
                6. Roasted Duck Noodle Soup <span>13.95</span>
              </h4>
              <p>
                Rice noodles with sliced roasted duck and bean sprouts in duck
                broth, topped with chopped scallion and garlic
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="noodlesRice">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#noodles"
                aria-expanded="false"
                aria-controls="noodles"
              >
                Noodles and Fried Rice (NF)
              </button>
            </h5>
          </div>
          <div
            id="noodles"
            class="collapse"
            aria-labelledby="noodlesRice"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p className="meat">
                Vegetables & Tofu <span>(Lunch) 10.95 / (Dinner) 12.95</span>
                <br />
                Chicken, Beef, or Pork{" "}
                <span>(Lunch) 11.95 / (Dinner) 13.95</span>
                <br />
                Scallops, Shrimp, or Squid{" "}
                <span>(Lunch) 12.95 / (Dinner) 14.95</span>
                <br />
                Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </p>

              <h4>1. Pad Thai</h4>
              <p>
                Stir-fried with rice noodles, egg, scallions, bean sprouts, and
                ground peanuts
              </p>
              <h4>2. Fiery Pad Thai</h4>
              <p>Spicy version of traditional Pad Thai</p>
              <h4>3. Drunken Noodles</h4>
              <p>
                Wide noodles stir-fried with egg, onion, carrots, green peppers,
                basil, and scallion
              </p>
              <h4>4. Pad See-U</h4>
              <p>
                Stir-fried with wide noodles, cabbage, broccoli, carrots, and
                bean sprouts
              </p>
              <h4>5. Thai Special Fried Rice</h4>
              <p>Fried rice with egg, peas, onion, and scallions</p>
              <h4>6. Pineapple Fried Rice</h4>
              <p>
                Fried rice with egg, pineapple, onion, peas, scallions, and
                curry powder
              </p>
              <h4>7. See You in “Kale”</h4>
              <p>
                A healthy combination of pad see-u and the super green vegetable
                “Kale” stir-fried and seasoned with light sauce and tofu
              </p>
              <h4>8. Basil Fried Rice</h4>
              <p>House fried rice with basil and spicy prik paw</p>
              <h4>9. Thai Lo Mein</h4>
              <p>Egg noodles with bokchoy, Napa cabbage, peppers and carrot</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="combo">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#combination"
                aria-expanded="false"
                aria-controls="combination"
              >
                Popular Combinations (PC)
              </button>
            </h5>
          </div>
          <div
            id="combination"
            class="collapse"
            aria-labelledby="combo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p>
                Create your stir-fried dish by selecting type of meat or
                vegetables, along with a sauce or style listed below. Served
                with White Rice
              </p>
              <p className="meat">
                Vegetables & Tofu <span>(Lunch) 10.95 / (Dinner) 14.95</span>
                <br />
                Chicken, Beef, or Pork
                <span>(Lunch) 11.95 / (Dinner) 15.95</span>
                <br />
                Scallops, Shrimp, or Squid
                <span>(Lunch) 12.95 / (Dinner) 16.95</span>
                <br />
                Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </p>

              <h4>1. Basil</h4>
              <p>
                Basil, green peppers, onion, mushrooms, broccoli, zucchini, and
                spicy Thai basil sauce
              </p>
              <h4>2. Cashew Nuts</h4>
              <p>
                Pineapple, zucchini, carrots, onion, green peppers, mushrooms,
                cashew nuts
              </p>
              <h4>3. Broccoli</h4>
              <p>Broccoli, carrots, mushrooms</p>
              <h4>4. Peanut Sauce</h4>
              <p>
                Sauteed in peanut sauce with onion, carrots, mushrooms,
                zucchini, broccoli, and potato
              </p>
              <h4>5. Garlic</h4>
              <p>Garlic, mushrooms, red peppers, scallions, and peas</p>
              <h4>6. Ginger</h4>
              <p>Ginger sauce with mushroom, scallions, and bell peppers</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="curry">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#curryRice"
                aria-expanded="false"
                aria-controls="curryRice"
              >
                Curry
              </button>
            </h5>
          </div>
          <div
            id="curryRice"
            class="collapse"
            aria-labelledby="curry"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p>Served with White Rice</p>
              <p className="meat">
                Vegetables & Tofu <span>(Lunch) 10.95 / (Dinner) 15.95</span>
                <br />
                Chicken, Beef, or Pork
                <span>(Lunch) 11.95 / (Dinner) 16.95</span>
                <br />
                Scallops, Shrimp, or Squid
                <span>(Lunch) 12.95 / (Dinner) 18.95</span>
                <br />
                Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </p>

              <h4>1. Red Curry </h4>
              <p>Bamboo shoots, bell peppers and basil</p>
              <h4>2. Yellow Curry</h4>
              <p>Pineapple, potato, carrots, and onion</p>
              <h4>3. Green Curry</h4>
              <p>
                Bamboo shoots, broccoli, peas, green peppers, green beans, and
                basil
              </p>
              <h4>4. Panang Curry</h4>
              <p>String beans, bell peppers and lime leaves</p>
              <h4>5. Massaman Curry</h4>
              <p>Peanuts, potato, onion, and carrots</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="house">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#special"
                aria-expanded="false"
                aria-controls="special"
              >
                House Specialties (HS)
              </button>
            </h5>
          </div>
          <div
            id="special"
            class="collapse"
            aria-labelledby="house"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p>Served with White Rice (Except Khao Soi)</p>

              <h4>
                1. Tamarind Fish <span>(Lunch) 11.95 / (Dinner) 18.95</span>
              </h4>
              <p>
                Whole red snapper or salmon in sweet and sour tamarind sauce
                with assorted vegetables.
              </p>
              <h4>
                2. Mango Curry <span>(Lunch) 12.50 / (Dinner) 17.95</span>
              </h4>
              <p>
                Chicken and shrimp with mango chunks in a yellow curry with
                coconut milk, onions, carrots, tomatoes, and bell peppers.
              </p>
              <h4>
                3. Spicy Fish <span>(Lunch) 11.95 / (Dinner) 18.95</span>
              </h4>
              <p>
                Whole red snapper or salmon in home-made spicy chili sauce with
                assorted vegetables and crispy basil leaves.
              </p>
              <h4>
                4. Thai & I Special <span>(Lunch) 11.95 / (Dinner) 15.95</span>
              </h4>
              <p>
                Ground beef, chicken, or pork, stir-fried with spicy authentic
                basil sauce, fresh chilies, diced string beans bell peppers, and
                Thai basil leaves; served with steamed rice and Thai-style fried
                egg.
              </p>
              <h4>
                5. Veggies Delight <span>(Lunch) 11.95 / (Dinner) 15.95</span>
              </h4>
              <p>Variety of vegetables stir fried with light soy sauce.</p>
              <h4>
                6. Khao Soi <span>(Lunch) 12.95 / (Dinner) 15.95</span>
              </h4>
              <p>
                The famous northern noodles in delicious mixed of light yellow
                curry, chicken, scallions and fried shallots, vegetarian version
                available.
              </p>
              <h4>
                7. Seafood Basil <span>(Lunch) 13.95 / (Dinner) 18.95</span>
              </h4>
              <p>
                Combination of shrimp, squid, and scallops, in spicy basil
                sauce.
              </p>
              <h4>
                8. Lemongrass <span>(Lunch) 12.95 / (Dinner) 16.95</span>
              </h4>
              <p>Peppers, onion, carrot, and lemongrass with crispy chicken.</p>
              <h4>
                9. Crispy Chicken Basil{" "}
                <span>(Lunch) 12.95 / (Dinner) 16.95</span>
              </h4>
              <p>
                Peppers, onions, and crispy chicken, topped with crispy basil.
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="duck">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#duckVarieties"
                aria-expanded="false"
                aria-controls="duckVarieties"
              >
                Duck Varieties (D)
              </button>
            </h5>
          </div>
          <div
            id="duckVarieties"
            class="collapse"
            aria-labelledby="duck"
            data-parent="#accordion"
          >
            <div class="card-body">
              <p>Served with White Rice</p>
              <h4>
                1. Boston Volcano <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </h4>
              <p>
                Crispy duck coated with tamarind sauce, cashew nuts, mushrooms,
                carrots, and peas
              </p>
              <h4>
                2. Rama Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </h4>
              <p>Crispy roasted duck simmered in red curry and peanut sauce</p>
              <h4>
                3. Bangkok Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </h4>
              <p>
                Roasted duck sliced and de-boned; topped with ginger sauce and
                served with vegetables.
              </p>
              <h4>
                4. Orange Duck <span>(Lunch) 13.95 / (Dinner) 20.95</span>
              </h4>
              <p>
                Crispy roasted duck simmered in orange sauce with mixed
                vegetables.
              </p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="sideDishes">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#sides"
                aria-expanded="false"
                aria-controls="sides"
              >
                Side Dishes
              </button>
            </h5>
          </div>
          <div
            id="sides"
            class="collapse"
            aria-labelledby="sideDishes"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                Steamed Jasmine Rice
                <span>2.95</span>
              </h4>
              <h4>
                Peanut Sauce
                <span>2.95</span>
              </h4>
              <h4>
                Brown Rice
                <span>3.95</span>
              </h4>
              <h4>
                House Salad
                <span>3.95</span>
              </h4>
              <h4>
                Coconut Rice
                <span>4.95</span>
              </h4>
              <h4>
                Sticky Rice
                <span>4.95</span>
              </h4>
              <h4>
                Steam Mixed Vegetable with Side of Peanut Sauce
                <span>7.95</span>
              </h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="bev">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#beverages"
                aria-expanded="false"
                aria-controls="beverages"
              >
                Beverages
              </button>
            </h5>
          </div>
          <div
            id="beverages"
            class="collapse"
            aria-labelledby="bev"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                Thai Hot Tea (refillable) <span>2.00</span>
              </h4>
              <h4>
                Soda (refillable) <span>2.50</span>
              </h4>
              <p>Coke, diet coke, sprite, ginger ale</p>
              <h4>
                Thai Iced Tea <span>3.00</span>
              </h4>
              <p>(sweeten or unsweetened)</p>
              <h4>
                Thai Iced Coffee <span>3.00</span>
              </h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="des">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#dessert"
                aria-expanded="false"
                aria-controls="dessert"
              >
                Dessert
              </button>
            </h5>
          </div>
          <div
            id="dessert"
            class="collapse"
            aria-labelledby="des"
            data-parent="#accordion"
          >
            <div class="card-body">
              <h4>
                Banana Rolls with Honey <span>6.95</span>
              </h4>
              <h4>
                Mango with Sweet Sticky Rice <span>8.95</span>
              </h4>
              <h4>
                Thai Custard with Sweet Sticky Rice <span>8.95</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hours">
      <div className="container center">
        <h2>Hours</h2>
        <p>Open 7 Days a Week from 11AM - 9PM</p>
        <p>
          274 South St. Shrewsbury, MA 01545
          <br />
          Tel: 508-762-4810 / 508-762-4814
        </p>
      </div>
    </section>
  </Layout>
)

export default IndexPage
